// @ts-ignore
import React from "react";
import "./App.css";
import { useWeb3React } from "@web3-react/core";
import { SwapWidget } from "@pangolindex/components";
import { injected, useEagerConnect, useInactiveListener } from "./utils/hooks";
import LOGOIMG from "./assets/newlogo.png";
function App() {
  const context = useWeb3React();
  const { account } = context;
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager);

  return (
    <div className="App">
      <div className="header">
        <div className="logoIMG">
          <img src={LOGOIMG} alt="logoimg" />
        </div>
        <div className="rightActive">
           <button
              onClick={() => {
                injected.activate();
            }}
            className="backSiteBtn"
            >
              {!account ? <h4>Connect Wallet</h4> : (
                <h4>{account.slice(0, 3)} .. {account.slice(-2)}</h4>
              )}
            </button>
          <button className="backSiteBtn">
            <a href="https://app.doodcats.net/" style={{textDecoration:"none"}}>Staking</a>
          </button>
        </div>
      </div>
      <div style={{ width: "100%", display:"flex"}}>
        <div className="backBtnDiv1">
        </div>
        <div className="backBtnDiv2">
        
        </div>
        <div className="backBtnDiv3"/>
      </div>
      <h1 className="fire">SWAP</h1>
      <div className="swap">
       
     </div>
      <div style={{ marginTop: "10px", maxWidth: "400px",  margin:" 30px"}}>
        <SwapWidget
        defaultInputToken=""
        defaultOutputToken="0x697bb3B5E1eCf4fEbE6016321b0648d3d6C270B6" 
        showSettings
        />
      </div>
    </div>
    
    
  );
}

export default App;
